/* @flow */

import React, { useContext } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/hooks/use-format";
import { StoreInfoContext } from "entrypoint/shared";
import { arrHasSku } from "helpers/utils";

import styles from "./styles.scss";

type Props = {
  amount: number,
  sku?: string,
  skuArr?: Array<string>,
};

export const DONATION_PERCENT = 5;
export const excludedSKU: Array<string> = [
  // Configurable product Strawberry
  "37236-37240",
  // Individual variants for Strawberry
  "37236",
  "37237",
  "37238",
  "37239",
  "37240",
];

const DonationInfo = ({ amount, sku, skuArr }: Props): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const { club } = useContext(StoreInfoContext);

  if (!club || !amount ||
    arrHasSku(skuArr, excludedSKU) === true) {
    return null;
  }

  if (excludedSKU.includes(sku)) {
    return (
      <span className={styles.donationInfo}>
        {t("DONATION.INFO", {
          amount: formatPrice(amount * (10 / 100)),
          club,
        })}
      </span>
    );
  }

  return (
    <span className={styles.donationInfo}>
      {t("DONATION.INFO", {
        amount: formatPrice(amount * (DONATION_PERCENT / 100)),
        club,
      })}
    </span>
  );
};

export default DonationInfo;
